import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class BannerHeader extends React.Component {

    render() {
        return (
          <>
            <div className="header" style={{background: '#A6A6A6'}}>
                <img src={require("assets/img/banner_navidad.gif")} alt="" width="1080px" style={{display:'block', margin:'auto'}}/>
                
            </div>
          </>
        );
      }
}

export default BannerHeader;